import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { Routes, Route, useNavigate } from "react-router-dom";
import NotFound from "./NotFound";
import NotActive from "./NotActive";
import UsersLayout from "./UsersLayout";
import Elasticidad from "./Elasticidad";
import MarketShare from "./MarketShare";
import VentaUnidadesDashboard from "./VentaUnidadesDashboard";
import StockDashboard from "./StockDashboard";
import TestDashboard from "./TestDashboard";
import Surtido from "./Surtido";
import StockSurtido from "./StockSurtido";
import SSS from "./SSS";

import { signUserOut, resetPassword } from "../utils/firebase";

import "../scss/Main.scss";
import VentaUnidadesTiposVenta from "./VentaUnidadesTiposVenta";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#000000",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Main2({
  user,
  setUser,
  organization,
  setOpenSnackBar,
  setSeveritySnackBar,
  setMessageSnackBar,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dashboards, setDashboards] = useState(null);

  useEffect(() => {
    if (user.role === "super-admin") {
      setDashboards([
        { name: "Elasticidad", endpoint: "elasticidad" },
        { name: "Market Share", endpoint: "market_share" },
        { name: "Venta Unidades", endpoint: "venta_unidades" },
        { name: "Stock", endpoint: "stock" },
        { name: "Surtido", endpoint: "surtido" },
        { name: "Ventas SSS", endpoint: "sss" },
        { name: "Ventas Tipos", endpoint: "venta_tipos" },
        { name: "Test", endpoint: "test" },
      ]);
    } else {
      if (organization.name === "Liquidos.cl") {
        setDashboards([
          { name: "Elasticidad", endpoint: "elasticidad" },
          { name: "Market Share", endpoint: "market_share" },
          { name: "Venta Unidades", endpoint: "venta_unidades" },
          { name: "Stock", endpoint: "stock" },
          { name: "Surtido", endpoint: "surtido" },
          { name: "Ventas SSS", endpoint: "sss" },
          { name: "Ventas Tipos", endpoint: "venta_tipos" },
          { name: "Test", endpoint: "test" },
        ]);
      } else {
        const orgDashboards = organization.dashboards;
        orgDashboards.sort((a, b) => a.name.localeCompare(b.name));
        setDashboards(orgDashboards);
      }
    }
  }, [organization]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const config = {
    headers: {
      Authorization: user.accessToken,
    },
  };

  const drawerArray =
    user.role === "user"
      ? dashboards?.map((dashboard) => dashboard.name.toUpperCase())
      : dashboards
          ?.map((dashboard) => dashboard.name.toUpperCase())
          .concat("USUARIOS");

  const navigateToRoute = (index) => {
    if (user.role === "user") {
      navigate(`/${dashboards[index].endpoint}`);
    } else {
      if (index === dashboards.length) {
        navigate(`/users`);
      } else {
        navigate(`/${dashboards[index].endpoint}`);
      }
    }
  };

  const passwordResetHandler = () => {
    resetPassword(user.email)
      .then(() => {
        setOpenSnackBar(true);
        setSeveritySnackBar("success");
        setMessageSnackBar(
          "Tu solicitud de restablecimiento de contraseña fue recibida, revisa tu correo " +
            user.email
        );
        signUserOut();
        setUser(null);
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setSeveritySnackBar("error");
        setMessageSnackBar(
          "Error al solicitar restablecimiento de contraseña para el correo " +
            user.email
        );
        console.error("Error inesperado:", error);
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open} sx={{ height: "60px" }}>
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            color: "#FFDF00",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src="https://storage.googleapis.com/club-liquidos/club_liquidos_header.svg"
              style={{ height: "100%", width: "50%" }}
              alt="mi imagen"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="body">
              {user.displayName.toUpperCase()}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={(e) => {
                  signUserOut();
                  setUser(null);
                }}
              >
                Logout
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  passwordResetHandler();
                }}
              >
                Reset Password
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon
                sx={{
                  color: "#000000",
                  background: "#FFDF00",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <ChevronLeftIcon
                sx={{
                  color: "#000000",
                  background: "#FFDF00",
                  borderRadius: "50%",
                }}
              />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {drawerArray?.map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              onClick={() => {
                setSelectedIndex(index);
                navigateToRoute(index);
              }}
              sx={{
                color: "#FFDF00",
                ...(index === selectedIndex && {
                  bgcolor: "#FFDF00",
                  color: "#222831",
                }),
              }}
            >
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    color: "#FFDF00",
                    ...(index === selectedIndex && { color: "#222831" }),
                  }}
                >
                  {user.role === "user" ? (
                    index === 0 ? (
                      <AnalyticsIcon />
                    ) : index === 1 ? (
                      <AnalyticsIcon />
                    ) : index === 2 ? (
                      <AnalyticsIcon />
                    ) : index === 3 ? (
                      <AnalyticsIcon />
                    ) : index === 4 ? (
                      <AnalyticsIcon />
                    ) : index === 5 ? (
                      <AnalyticsIcon />
                    ) : (
                      <PeopleIcon />
                    )
                  ) : index === 0 ? (
                    <AnalyticsIcon />
                  ) : index === 1 ? (
                    <AnalyticsIcon />
                  ) : index === 2 ? (
                    <AnalyticsIcon />
                  ) : index === 3 ? (
                    <AnalyticsIcon />
                  ) : index === 4 ? (
                    <AnalyticsIcon />
                  ) : index === 5 ? (
                    <AnalyticsIcon />
                  ) : index === 6 ? (
                    <AnalyticsIcon />
                  ) : (
                    <PeopleIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      {user.active ? (
        <Box
          component="main"
          sx={{
            width: "100%",
            pt: "30px",
          }}
        >
          {user.role === "user" ? (
            <Routes>
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "market_share"
              ) && (
                <Route
                  path="/"
                  element={
                    <MarketShare
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "elasticidad"
              ) && (
                <Route
                  path="/elasticidad"
                  element={
                    <Elasticidad
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "market_share"
              ) && (
                <Route
                  path="/market_share"
                  element={
                    <MarketShare
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "venta_unidades"
              ) && (
                <Route
                  path="/venta_unidades"
                  element={
                    <VentaUnidadesDashboard
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "stock"
              ) && (
                <Route
                  path="/stock"
                  element={
                    <StockDashboard
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "test"
              ) && (
                <Route
                  path="/test"
                  element={
                    <TestDashboard
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "surtido"
              ) && (
                <Route
                  path="/surtido"
                  element={
                    <Surtido
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "stock_surtido"
              ) && (
                <Route
                  path="/stock_surtido"
                  element={
                    <StockSurtido
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "sss"
              ) && (
                <Route
                  path="/sss"
                  element={
                    <SSS
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "venta_tipos"
              ) && (
                <Route
                  path="/venta_tipos"
                  element={
                    <VentaUnidadesTiposVenta
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          ) : (
            <Routes>
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "market_share"
              ) && (
                <Route
                  path="/"
                  element={
                    <MarketShare
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "elasticidad"
              ) && (
                <Route
                  path="/elasticidad"
                  element={
                    <Elasticidad
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "market_share"
              ) && (
                <Route
                  path="/market_share"
                  element={
                    <MarketShare
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "venta_unidades"
              ) && (
                <Route
                  path="/venta_unidades"
                  element={
                    <VentaUnidadesDashboard
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "stock"
              ) && (
                <Route
                  path="/stock"
                  element={
                    <StockDashboard
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "test"
              ) && (
                <Route
                  path="/test"
                  element={
                    <TestDashboard
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "surtido"
              ) && (
                <Route
                  path="/surtido"
                  element={
                    <Surtido
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "stock_surtido"
              ) && (
                <Route
                  path="/stock_surtido"
                  element={
                    <StockSurtido
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "sss"
              ) && (
                <Route
                  path="/sss"
                  element={
                    <SSS
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              {dashboards?.some(
                (dashboard) => dashboard.endpoint === "venta_tipos"
              ) && (
                <Route
                  path="/venta_tipos"
                  element={
                    <VentaUnidadesTiposVenta
                      user={user}
                      setSelectedIndex={setSelectedIndex}
                      dashboards={dashboards}
                    />
                  }
                />
              )}
              <Route
                path="/users"
                element={
                  <UsersLayout
                    user={user}
                    setSelectedIndex={setSelectedIndex}
                    config={config}
                    organization={organization}
                    dashboards={dashboards}
                    setOpenSnackBar={setOpenSnackBar}
                    setSeveritySnackBar={setSeveritySnackBar}
                    setMessageSnackBar={setMessageSnackBar}
                  />
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            width: "100%",
            pt: "30px",
          }}
        >
          <Routes>
            <Route path="*" element={<NotActive />} />
          </Routes>
        </Box>
      )}
    </Box>
  );
}
