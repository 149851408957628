import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

const NotActive = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 8, background: 'black', paddingTop: 4, minHeight: '100vh' }}>
            <Typography sx={{ color: '#8D8D8D', textAlign: 'center', marginLeft: '10px', marginTop: '10px' }}> Usuario desactivado, por favor contactar por personal de liquidos.cl</Typography>
        </Box>
    );
}

export default NotActive;